import React, { useState } from "react";
import axios from "axios";

import {
  Modal,
  TouchableHighlight,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import GradientBackground from "../components/GradientBackground";
import Constants from "expo-constants";

const apiUrl = Constants.manifest.extra.API_URL;
const endPoint = Constants.manifest.extra.API_ENDPOINT_USERS;

function FormularioRegistroUsuario({ navigation }) {
  const [nombre, setNick] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmarPassword, setConfirmarPassword] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const validarCampos = () => {
    // Estandarización: Quitar espacios al inicio, final y entre palabras
    const nombreEstandarizado = nombre.trim().replace(/\s+/g, "");
    console.log(nombreEstandarizado);
    setNick(nombreEstandarizado);
    // Validación: Comprobar que la longitud esté entre 3 y 20
    const nombreValido =
      nombreEstandarizado.length >= 3 && nombreEstandarizado.length <= 20
        ? true
        : false;
    // Validar campo email con regex
    const emailValido = new RegExp(
      "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
    ).test(email);
    // Validar campo password con regex (debe tener al menos 8 caracteres, como mnimo 1 número, como minimo 1 mayúscula, como minimo 1 miniscula y como minimo 1 signo)
    const passwordValido =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[\d\w\W]{8,}/.test(password);

    if (!nombreValido) {
      alert("El nombre debe tener al menos 3 caracteres y un maximo de 20");
      return false;
    }

    if (!emailValido) {
      alert("El email no es válido");
      return false;
    }

    if (!passwordValido) {
      alert(
        "La contraseña debe tener al menos 8 caracteres, 1 número, 1 mayúscula, 1 minuscula y 1 signo"
      );
      return false;
    }

    return true;
  };

  const registrarUsuario = async (e) => {
    e.preventDefault();
    if (!validarCampos()) {
      return;
    }
    try {
      const response = await axios.post(
        apiUrl + endPoint,
        {
          username: nombre,
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        // Usuario creado correctamente
        //TODO: Cargar modal de bienvenida
        setModalVisible(true);
        // navigation.navigate("Login");
      }
    } catch (error) {
      console.log("error", error.response.data);
    }
  };

  return (
    <GradientBackground>
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Bienvenid@!</Text>
          <Text style={styles.subtitle}>Regístrate y disfruta!</Text>
        </View>
        <View style={styles.form}>
          <View style={styles.formGroup}>
            <TextInput
              placeholder="Nombre de Usuario"
              style={styles.input}
              value={nombre}
              onChangeText={(text) => setNick(text)}
            />
          </View>
          <View style={styles.formGroup}>
            <TextInput
              placeholder="Email"
              style={styles.input}
              value={email}
              onChangeText={(text) => setEmail(text)}
            />
          </View>
          <View style={styles.formGroup}>
            <TextInput
              placeholder="Password"
              style={styles.input}
              secureTextEntry
              value={password}
              onChangeText={(text) => setPassword(text)}
            />
          </View>
          <View style={styles.formGroup}>
            <TextInput
              placeholder="Confirmar Password"
              style={styles.input}
              secureTextEntry
              value={confirmarPassword}
              onChangeText={(text) => setConfirmarPassword(text)}
            />
          </View>
          <TouchableOpacity style={styles.button} onPress={registrarUsuario}>
            <Text style={styles.buttonText}>Enviar</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.lineSeparation}>
          <TouchableOpacity onPress={() => navigation.navigate("Login")}>
            <Text>¿Ya tienes cuenta? </Text>
            <Text style={styles.link}>Logate!</Text>
          </TouchableOpacity>
        </View>
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <View
            style={{
              width: 300,
              padding: 20,
              backgroundColor: "white",
              borderRadius: 10,
            }}
          >
            <Text>{nombre} Bienvenido y gracias por registrarte!!!</Text>
            <TouchableHighlight
              style={{
                backgroundColor: "#2196F3",
                padding: 10,
                marginTop: 10,
                borderRadius: 5,
              }}
              onPress={() => {
                setModalVisible(!modalVisible);
                navigation.navigate("Login");
              }}
            >
              <Text style={{ color: "white", textAlign: "center" }}>Login</Text>
            </TouchableHighlight>
          </View>
        </View>
      </Modal>
    </GradientBackground>
  );
}

/* Estilos para el formulario de registro */

const styles = StyleSheet.create({
  lineSeparation: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  link: {
    color: "#ffffff",
  },

  container: {
    paddingHorizontal: 40,
    paddingBottom: 20,
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
  },

  titleContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 25,
  },

  title: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 24,
    marginBottom: 10,
  },

  subtitle: {
    fontSize: 16,
    marginBottom: 10,
  },

  form: {
    width: "100%",
  },

  formGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },

  label: {
    fontSize: 15,
    fontWeight: "bold",
  },

  input: {
    flex: 1,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 10,
  },

  button: {
    backgroundColor: "#a1c4fd",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 20,
    width: "100%",
  },

  buttonText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
  },
});

export default FormularioRegistroUsuario;
