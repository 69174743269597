import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
  StyleSheet,
} from "react-native";
import GradientBackground from "../components/GradientBackground";
import Login from "../components/Login";
import Icon from "react-native-vector-icons/FontAwesome";

const LoginScreen = ({ navigation }) => {
  return (
    <GradientBackground>
      <Login navigation={navigation}></Login>
      {/* Vista para poner un boton de registro */}
      <View style={styles.container}>
        <Text style={styles.title}>¿No tienes una cuenta?</Text>
        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate("Registro")}
        >
          <Text style={styles.buttonText}>Registrate!</Text>
        </TouchableOpacity>
      </View>
    </GradientBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center",
  },
  title: {},
  buttonText: {
    paddingLeft: 10,
    color: "white",
  },
  input: {
    height: 40,
    width: "90%",
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 20,
    paddingLeft: 10,
  },
});

export default LoginScreen;
