import React, { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../services/AuthContext";

import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
  Image,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

const Login = ({ navigation }) => {
  const [isSelected, setSelection] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const { signIn } = React.useContext(AuthContext);

  const handleLogin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: username,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("http://95.179.222.253:8090/auth/login", requestOptions)
      .then((response) => response.json()) // Aquí cambiamos response.text() por response.json()
      .then(async (result) => {
        console.log(result);
        if (result.error) {
          // Aquí manejamos los errores devueltos por el servidor
          if (result.error == "El usuario no existe") {
            console.log("El usuario no existe");
            setUsernameError(result.error);
            setPasswordError(null);
          } else {
            console.log("Contraseña incorrecta");
            setPasswordError(result.error);
            setUsernameError(null);
          }
          return; // Salimos temprano del then en caso de error
        }

        var token = result.token;
        if (token) {
          try {
            await AsyncStorage.setItem("userToken", token);
            await AsyncStorage.setItem("userId", username);
            console.log("token guardado");
            signIn({ token: token });
          } catch (e) {
            // Este bloque catch maneja los errores relacionados con AsyncStorage
            console.error(e);
          }
        }
      })
      .catch((error) => console.log("error", error)); // Este bloque catch maneja los errores de red o de respuesta no JSON

    // Aquí puedes manejar la respuesta del servidor
  };

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <Icon style={styles.icon} name="user" size={30} color="#000" />
        <Text style={styles.title}>Login</Text>
      </View>
      <TextInput
        placeholder="Nombre de Usuario"
        style={styles.input}
        onChangeText={setUsername}
        value={username}
      />
      {usernameError && <Text style={styles.errorText}>{usernameError}</Text>}

      <TextInput
        placeholder="Contraseña"
        secureTextEntry={true}
        style={styles.input}
        onChangeText={setPassword}
        value={password}
      />
      {passwordError && <Text style={styles.errorText}>{passwordError}</Text>}

      <TouchableOpacity style={styles.button} onPress={handleLogin}>
        <Text style={styles.buttonText}>Login</Text>
      </TouchableOpacity>
      <View style={styles.optionsContainer}>
        <View style={styles.checkboxContainer}>
          <CheckBox
            style={styles.checkBox}
            value={isSelected}
            onValueChange={setSelection}
          />
          <Text style={styles.optionText}>Recordar</Text>
        </View>
        <TouchableOpacity style={styles.checkboxContainer}>
          <Text style={styles.optionText}>Contraseña Olvidada</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
  },
  logoContainer: {
    flexDirection: "column",
    alignItems: "center",
    top: -50,
    marginBottom: -30,
  },
  icon: {
    padding: 15,
    paddingLeft: 23,
    paddingRight: 23,
    borderRadius: 40,
    // Cambiar el color de fondo del icono de "#a1c4fd" a rgba
    backgroundColor: "rgba(161, 196, 253, 0.7)",
    //backgroundColor: "rgba(4, 32, 181, 0.3)",
  },
  title: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 24,
    marginBottom: 10,
  },
  input: {
    height: 40,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "gray",
    //borderWidth: 2,
    marginBottom: 20,
    paddingLeft: 10,
  },
  button: {
    backgroundColor: "#a1c4fd",
    padding: 10,
    borderRadius: 5,
    width: "100%",
    alignItems: "center",
    marginBottom: 5,
  },
  buttonText: {
    color: "#fff",
  },
  optionsContainer: {
    // paddingTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center", // Añade esto para alinear verticalmente los elementos hijos
  },
  // Tamaño del checkbox más pequeño
  checkBox: {
    transform: [{ scaleX: 0.5 }, { scaleY: 0.5 }],
  },

  optionText: {
    fontSize: 10, // Añade esto para hacer el texto más pequeño
  },

  errorText: {
    color: "red",
    marginTop: -20,
    marginBottom: 10,
  },
});

export default Login;
