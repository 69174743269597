import React from "react";
import { View, Platform, StyleSheet } from "react-native";
//import LinearGradient from "react-native-linear-gradient";

const GradientBackground = ({ children }) => {
  if (Platform.OS === "web") {
    return <View style={styles.webGradient}>{children}</View>;
  } else {
    return null;
    // return (
    //   <LinearGradient
    //     colors={["#a1c4fd", "#c2e9fb"]}
    //     style={styles.nativeGradient}
    //   >
    //     {children}
    //   </LinearGradient>
    // );
  }
};

const styles = StyleSheet.create({
  webGradient: {
    backgroundImage: "linear-gradient(to right, #1E90FF, #800080)",
    minHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  nativeGradient: {
    minHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default GradientBackground;
