import { Button, Easing } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import ListaTareasScreen from "../../screens/ListaTareasScreen";
import TareasScreen from "../../screens/TareasScreen";

const AppStack = createNativeStackNavigator();

const AppStackScreen = ({ authContext }) => (
  <AppStack.Navigator
    screenOptions={{
      gestureEnabled: true,
      cardOverlayEnabled: true,
      headerStyle: {
        backgroundColor: "#f7f7f7",
      },
    }}
  >
    <AppStack.Screen
      name="ListaTareas"
      component={ListaTareasScreen}
      options={{
        headerShown: true,
        headerRight: () => (
          <Button title="Logout" onPress={authContext.signOut} />
        ),
      }}
    />
    <AppStack.Screen
      name="Tareas"
      component={TareasScreen}
      options={{
        headerShown: true,
        headerRight: () => (
          <Button title="Logout" onPress={authContext.signOut} />
        ),
      }}
    />
  </AppStack.Navigator>
);

export default AppStackScreen;
