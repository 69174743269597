import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Button,
  TouchableOpacity,
  TextInput,
  FlatList,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from "react-native-vector-icons/FontAwesome";
import GradientBackground from "../components/GradientBackground";
import { Alert } from "react-native";
import Modal from "react-native-modal";
import TextTicker from "react-native-text-ticker";
import Constants from "expo-constants";
import AgregarItem from "../components/AgregarItem"; // Importa el componente AgregarItem

const apiUrl = Constants.manifest.extra.API_URL;
const endPoint = Constants.manifest.extra.API_ENDPOINT_TASKLISTS;

const ListaTareasScreen = ({ navigation }) => {
  const [listaTareas, setListaTareas] = useState([]); // Aquí se guardan las listas de tareas
  const [nuevaListaName, setNuevaListaName] = useState(""); // Nuevo estado para guardar el nombre de la nueva lista
  const [isModalVisible, setModalVisible] = useState(false); // Nuevo estado para controlar la visibilidad del modal
  const [showDeleteIcons, setShowDeleteIcons] = useState(false); // Nuevo estado para controlar la visibilidad de los iconos de eliminación

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleInputChange = (text) => {
    // Actualiza el estado cada vez que el texto del input cambia
    setNuevaListaName(text);
  };

  const actualizarListaTareas = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const response = await fetch(apiUrl + endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // Manejar error
      console.error("Error al obtener la lista de tareas");
      return;
    }

    const data = await response.json();
    console.log(data);
    setListaTareas(data);
  };

  useEffect(() => {
    actualizarListaTareas();
  }, []);

  const handleListaPress = (lista) => {
    console.log("Lista presionada:", lista);
    // Navegar a la pantalla de tareas pasando el id de la lista como parámetro
    navigation.navigate("Tareas", { listaTareas: lista });
  };

  const handleEliminarLista = (id) => {
    console.log("Eliminar lista:", id);
    const confirm = window.confirm(
      "¿Estás seguro de que quieres eliminar esta lista?"
    );
    console.log(confirm);
    if (confirm) {
      // Lógica para eliminar la lista
      eliminarLista(id);
      console.log("Lista eliminada:", id);
    } else {
      console.log("Eliminación cancelada");
    }
  };

  const eliminarLista = async (id) => {
    const token = await AsyncStorage.getItem("userToken");
    const response = await fetch(apiUrl + endPoint + "/" + `${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      // Manejar error
      console.error("Error al eliminar la lista de tareas");
      return;
    }
    console.log(response);

    // Vuelve a obtener la lista de tareas para actualizar la UI
    actualizarListaTareas();
  };

  const handleAgregarLista = async (nombreLista) => {
    if (!nombreLista.trim()) {
      console.log("El nombre de la lista no puede estar vacío");
      return;
    }
    console.log("Url:", apiUrl + endPoint);
    console.log("Agregar lista:", nombreLista);
    // Para crear la nueva lista de tareas, debes hacer una petición POST a la ruta http://localhost:8090/api/tasks
    // con el nombre de la nueva lista y el userId en el body de la petición y el token en el header Authorization
    let nuevaLista = {};
    nuevaLista.name = nombreLista;
    nuevaLista.userId = await AsyncStorage.getItem("userId");
    console.log("Nueva lista:", nuevaLista);
    const token = await AsyncStorage.getItem("userToken");
    const response = await fetch(apiUrl + endPoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nuevaLista),
    });

    if (!response.ok) {
      // Manejar error, esperando a la respuesta del servidor
      const errorText = await response.text();
      console.error("Error al agregar la lista de tareas", errorText);
      alert(errorText);
      return;
    }

    // Limpia el campo de texto después de agregar la lista
    setNuevaListaName("");

    // Vuelve a obtener la lista de tareas para actualizar la UI
    actualizarListaTareas();
  };

  return (
    <GradientBackground>
      <View style={styles.container}>
        <Text style={styles.titulo}>Listas de tareas</Text>
        {listaTareas.map((lista) => (
          <View key={lista.id} style={styles.listaTareas}>
            <TouchableOpacity
              onPress={() => handleListaPress(lista)}
              style={styles.taskListContainer}
            >
              <TextTicker
                style={styles.nombreCategoria}
                // numberOfLines={1}
                // ellipsizeMode="tail"
                duration={7000}
                loop
                bounce
                repeatSpacer={50}
                marqueeDelay={1000}
              >
                {lista.name}
              </TextTicker>
            </TouchableOpacity>
            {showDeleteIcons && (
              <TouchableOpacity onPress={() => handleEliminarLista(lista.id)}>
                <Icon name="trash" size={24} color="#333" />
              </TouchableOpacity>
            )}
          </View>
        ))}
        <AgregarItem
          placeholder="Nueva Lista"
          onInputChange={handleInputChange}
          onAddItem={handleAgregarLista}
          buttonText="Agregar"
          showDeleteIcons={showDeleteIcons}
          setShowDeleteIcons={setShowDeleteIcons}
        />
      </View>
    </GradientBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    maxWidth: 400,
    // backgroundColor: "#f8f8f8",
  },
  titulo: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#333",
    alignSelf: "center",
  },
  listaTareas: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  nombreCategoria: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
  },
  taskListContainer: {
    flex: 1,
    marginRight: 10, // Espacio a la derecha para evitar que el texto se superponga con los íconos
  },
  agregarLista: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // Añade esta línea
    marginTop: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  inputNombreLista: {
    flex: 1,
    marginRight: 10,
    padding: 10,
    fontSize: 16,
    color: "#333",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
  },
  botonAgregar: {
    marginRight: 10,
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#007BFF",
  },
  botonEliminar: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  textoBoton: {
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
  },
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  modalText: {
    marginBottom: 20,
    fontSize: 16,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
  },
  button: {
    flex: 1,
    margin: 5,
  },
});

export default ListaTareasScreen;
