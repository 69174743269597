import { createNativeStackNavigator } from "@react-navigation/native-stack";

import LoginScreen from "../../screens/LoginScreen";
import FormularioRegistroUsuario from "../../screens/FormularioRegistroUsuario";

const AuthStack = createNativeStackNavigator();

const AuthStackScreen = () => (
  <AuthStack.Navigator screenOptions={{ headerShown: false }}>
    <AuthStack.Screen name="Login" component={LoginScreen} />
    <AuthStack.Screen name="Registro" component={FormularioRegistroUsuario} />
  </AuthStack.Navigator>
);

export default AuthStackScreen;
