import React from "react";
import { View, TextInput, TouchableOpacity, Text } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

const AgregarItem = ({
  placeholder,
  onInputChange,
  onAddItem,
  buttonText,
  showDeleteIcons,
  setShowDeleteIcons,
}) => {
  const [nuevoItem, setNuevoItem] = React.useState("");

  const handleAgregarItem = () => {
    onAddItem(nuevoItem);
    setNuevoItem("");
  };

  return (
    <View style={styles.agregarItem}>
      <TextInput
        placeholder={placeholder}
        style={styles.inputNombreItem}
        value={nuevoItem}
        onChangeText={(text) => {
          onInputChange(text);
          setNuevoItem(text);
        }}
      />
      <TouchableOpacity style={styles.botonAgregar} onPress={handleAgregarItem}>
        <Text style={styles.textoBoton}>{buttonText}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.botonEliminar}
        onPress={() => setShowDeleteIcons(!showDeleteIcons)}
      >
        <Icon
          name={showDeleteIcons ? "times" : "edit"}
          size={18}
          color="#fff"
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = {
  agregarItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // Añade esta línea
    marginTop: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  inputNombreItem: {
    flex: 1,
    marginRight: 10,
    paddingBottom: 10,
    paddingTop: 10,
    fontSize: 16,
    color: "#333",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
  },
  botonAgregar: {
    marginRight: 10,
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#007BFF",
  },
  botonEliminar: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  textoBoton: {
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
  },
};

export default AgregarItem;
