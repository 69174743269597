import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Button,
  TouchableOpacity,
  TextInput,
  FlatList,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from "react-native-vector-icons/FontAwesome";
import GradientBackground from "../components/GradientBackground";
import { Alert } from "react-native";
import Modal from "react-native-modal";
import TextTicker from "react-native-text-ticker";
import TaskItem from "../components/TaskItem";

const TareasScreen = ({ navigation, route }) => {
  const [newTaskList, setNewTaskList] = useState(
    route.params.listaTareas || { tasks: [] }
  );
  const [showOptionsIcons, setShowOptionsIcons] = useState(false);
  const [newTaskName, setNewTaskName] = useState("");
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editingTaskName, setEditingTaskName] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    updateTaskList();
  }, []);

  // Función para agregar una nueva tarea
  const addNewTask = async (taskName) => {
    //console.log("Agregar tarea:", taskName);
    const token = await AsyncStorage.getItem("userToken");
    if (taskName === "") {
      Alert.alert("Error", "Debes ingresar un nombre para la tarea");
      console.log("Error: Debes ingresar un nombre para la tarea");
      return;
    }
    const url = `http://95.179.222.253:8090/api/tasklists/${newTaskList.id}/tasks`;
    let task = {
      name: taskName,
      description: "",
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    });

    if (!response.ok) {
      // Manejar error
      console.error("Error al agregar la lista de tareas");
      return;
    }

    const data = await response.json();

    setNewTaskList(data);

    // Limpia el campo de texto después de agregar la tarea
    setNewTaskName("");

    // Vuelve a obtener la lista de tareas para actualizar la UI
    updateTaskList();
  };

  const updateTaskList = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const url = `http://95.179.222.253:8090/api/tasklists/${newTaskList.id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // Manejar error
      console.error("Error al obtener la lista de tareas");
      return;
    }

    const data = await response.json();
    console.log(data);
    setNewTaskList(data);
  };

  const deleteTask = async (taskId) => {
    const token = await AsyncStorage.getItem("userToken");
    const url = `http://95.179.222.253:8090/api/tasklists/${newTaskList.id}/tasks/${taskId}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // Manejar error
      console.error("Error al eliminar la tarea");
      return;
    }

    // Vuelve a obtener la lista de tareas para actualizar la UI
    updateTaskList();
  };

  const completeTask = async (task) => {
    const token = await AsyncStorage.getItem("userToken");
    const completed = !task.completed;
    console.log("Completar tarea:", task.id, completed);
    const url = `http://95.179.222.253:8090/api/tasklists/${newTaskList.id}/tasks/${task.id}/complete?isCompleted=${completed}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // Manejar error
      console.error("Error al completar la tarea");
      return;
    }

    // Vuelve a obtener la lista de tareas para actualizar la UI
    updateTaskList();
  };

  const editTaskName = async (taskId, newTaskName) => {
    const token = await AsyncStorage.getItem("userToken");
    const url = `http://95.179.222.253:8090/api/tasklists/${newTaskList.id}/tasks/${taskId}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: newTaskName }),
    });

    if (!response.ok) {
      // Manejar error
      console.error("Error al editar el nombre de la tarea");
      return;
    }

    // Vuelve a obtener la lista de tareas para actualizar la UI
    updateTaskList();
  };

  return (
    <GradientBackground>
      <View style={styles.container}>
        <Text style={styles.titulo}>{newTaskList.name}</Text>
        {newTaskList.tasks &&
          newTaskList.tasks.map((task) => (
            <TaskItem
              key={task.id}
              task={task}
              onDelete={deleteTask}
              onComplete={completeTask}
              onEdit={editTaskName}
              isEditMode={isEditMode}
            />
          ))}
        <View style={styles.agregarTarea}>
          <TextInput
            placeholder="Nueva Tarea"
            style={styles.inputNombreTarea}
            value={newTaskName}
            onChangeText={(text) => setNewTaskName(text)}
          />
          <TouchableOpacity
            style={styles.botonAgregar}
            onPress={() => addNewTask(newTaskName)}
          >
            <Text style={styles.textoBoton}>Agregar</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.botonEditar}
            onPress={() => setIsEditMode(!isEditMode)}
          >
            <Icon name={isEditMode ? "times" : "edit"} size={18} color="#fff" />
          </TouchableOpacity>
        </View>
      </View>
    </GradientBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    maxWidth: 400,
    // backgroundColor: "#f8f8f8",
  },
  titulo: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#333",
    alignSelf: "center",
  },
  taskNameContainer: {
    flex: 1,
    marginRight: 10, // Espacio a la derecha para evitar que el texto se superponga con los íconos
  },
  tasks: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  agregarTarea: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between", // Añade esta línea
    marginTop: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  inputNombreTarea: {
    flex: 1,
    marginRight: 10,
    padding: 10,
    fontSize: 16,
    color: "#333",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
  },
  botonAgregar: {
    marginRight: 10,
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#007BFF",
  },
  optionsIcons: {
    flexDirection: "row",
  },
  botonEditar: {
    padding: 10,
    fontSize: 24,
    borderRadius: 5,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  botonEditarTarea: {
    marginLeft: 10,
  },
  botonEliminar: {
    marginLeft: 10,
  },
  textoBoton: {
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
  },
  modalContent: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  modalText: {
    marginBottom: 20,
    fontSize: 16,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
  },
  button: {
    flex: 1,
    margin: 5,
  },
});

export default TareasScreen;
