import React, { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
  Image,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import TextTicker from "react-native-text-ticker";

const TaskItem = ({ task, onDelete, onComplete, onEdit, isEditMode }) => {
  const [editingTaskName, setEditingTaskName] = useState(task.name);

  const handleEdit = () => {
    onEdit(task.id, editingTaskName);
    setEditingTaskName(task.name);
  };

  return (
    <View style={styles.tasks}>
      <TouchableOpacity
        onPress={() => !isEditMode && onComplete(task)}
        style={styles.taskNameContainer}
      >
        {isEditMode ? (
          <TextInput
            value={editingTaskName}
            onChangeText={setEditingTaskName}
            onSubmitEditing={() => onEdit(task.id, editingTaskName)}
            onBlur={() => onEdit(task.id, editingTaskName)} // Edit task name when the input loses focus
            autoFocus
          />
        ) : (
          <TextTicker
            style={styles.tasksName}
            duration={7000}
            loop
            bounce
            repeatSpacer={50}
            marqueeDelay={1000}
          >
            {task.name}
          </TextTicker>
        )}
      </TouchableOpacity>
      {!isEditMode && (
        <TouchableOpacity onPress={() => onComplete(task)}>
          <Icon
            name={task.completed ? "check-circle" : "circle-thin"}
            size={24}
            color="#333"
          />
        </TouchableOpacity>
      )}
      {isEditMode && (
        <TouchableOpacity onPress={() => onDelete(task.id)}>
          <Icon
            name="trash"
            style={styles.botonEliminar}
            size={24}
            color="#333"
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  tasks: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: "#fff",
    shadowColor: "#333",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  taskNameContainer: {
    flex: 1,
  },
  tasksName: {
    fontSize: 16,
    color: "#333",
  },
  botonEliminar: {
    marginLeft: 10,
  },
});

export default TaskItem;
