import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "/src/services/AuthContext";

import AppStackScreen from "./src/components/NavBar/AppStackScreen";
import AuthStackScreen from "./src/components/NavBar/AuthStackScreen";

const RootStack = createNativeStackNavigator();

const RootStackScreen = ({ userToken, authContext }) => (
  <RootStack.Navigator screenOptions={{ headerShown: false }}>
    {userToken == null ? (
      <RootStack.Screen name="Auth" component={AuthStackScreen} />
    ) : (
      <RootStack.Screen
        name="App"
        children={() => <AppStackScreen authContext={authContext} />}
      />
    )}
  </RootStack.Navigator>
);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [isNavBarVisible, setIsNavBarVisible] = useState(true);

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        setUserToken("dummy-token");
      },
      signOut: async () => {
        setUserToken(null);
        await AsyncStorage.removeItem("userToken");
        await AsyncStorage.removeItem("userId");
      },
    }),
    []
  );

  const restoreToken = async () => {
    let token;
    try {
      token = await AsyncStorage.getItem("userToken");
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    setUserToken(token);
  };

  useEffect(() => {
    restoreToken();
  }, []);

  if (isLoading) {
    return null; // Puedes mostrar un splash screen aquí
  }

  return (
    <AuthContext.Provider value={authContext}>
      <NavigationContainer>
        <RootStack.Navigator screenOptions={{ headerShown: false }}>
          <RootStack.Screen
            name="Root"
            children={() => (
              <RootStackScreen
                userToken={userToken}
                authContext={authContext}
              />
            )}
          />
        </RootStack.Navigator>
      </NavigationContainer>
    </AuthContext.Provider>
  );
};

export default App;
